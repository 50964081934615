<template>
  <div :class="`${customClass}`">
    <Text size="sm" weight="semibold" color="gray-700" :content="label" customClass="uppercase mb-1"/>
    <Text size="sm" weight="normal" color="gray-500" :content="helpText"/>
    <div class="flex mt-3  max-w-sm w-full ">
      <div class="h-12 w-8 flex justify-center items-center bg-gray-50 border rounded-l">
        <Text size="" weight="semibold" color="gray-600" :content="currency"/>
      </div>
      <input v-model="inputValue" :placeholder="placeholder" class="h-12 text-base border w-full bg-white px-3 outline-none focus:border-primary " @blur="isInputActive = false" @focus="() => {isInputActive = true; $emit('onFocus')}" :class="currency.length > 0 ? 'rounded-r' : 'rounded'"/>
    </div>

  </div>
</template>

<script>
import Icon from "../../../atoms/Icons/Icons.vue";
import Text from "../../../atoms/Text/Text.vue";

/**
 * - Use it to show a input form with help text
 */
export default {
  components: {
    Icon,
    Text,
  },
  props: {
    /**
     * Use to show what input form is for
     */
    label: {
      type: String,
      default: "",
    },
    /**
     * Placeholder of input form
     */
    placeholder: {
      type: String,
      default: "",
    },
    /**
     * Use it to apply extra class to input form
     */
    customClass: {
      type: String,
      default: "",
    },
    /**
     * Use to catch dynamic value of input form
     */
    value: {
      type: String,
      default: "",
    },
    /**
     * Use for help text
     */
    helpText: {
      type: String,
      default: "",
    },
    currency: {
      type: String,
      default: "",
    },
  },
  data: function () {
    return {
      isInputActive: false,
    };
  },
  computed: {
    inputValue: {
      get: function () {
        if (this.isInputActive) {
          return this.value.toString();
        } else {
          return Number(this.value).toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
        }
      },
      set: function (modifiedValue) {
        let newValue = parseFloat(modifiedValue.replace(/[^\d\.]/g, ""));
        if (isNaN(newValue)) {
          newValue = 0;
        }
        this.$emit("update:value", newValue);
      },
    },
  },
};
</script>

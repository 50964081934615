<template>
    <div class="max-w-md px-0 lg:px-10 flex flex-col justify-center items-center space-y-10">
        <div class="relative flex justify-center items-center">
            <div
                class="flex justify-center bg-white items-start shadow-lg rounded-md border-l-4 border-primary px-3 py-4">
                <Text size="sm" color="gray-400" v-html="helpHtml" customClass="h-36 overflow-y-scroll"/>
            </div>
            <div class="h-6 w-6 bg-white absolute -bottom-3 transform rotate-45 bg-white"></div>
        </div>
        <Avatar :src="require('../../../../assets/public/faces/Face1.png')" isCircle size="56" customClass="border-8 border-primary bg-white"/>
        <Text size="lg" weight="bold" color="gray-600" :content="label"/>
    </div>
</template>

<script>
import Text from '../../../atoms/Text/Text.vue'
import Avatar from '../../../atoms/Avatar/Avatar.vue'
/**
 - Use it to show a help view
 **/
export default {
    components: {
        Text,
        Avatar
    },
    props: {
        /**
         * title
         */
        label: {
            type: String,
            default: ''
        },
        helpHtml: {
            type: String,
            default: ''
        },
    }
};
</script>

<style>

</style>

<template>
  <div>
    <Text
      size="sm"
      weight="semibold"
      color="gray-700"
      :content="label"
      customClass="uppercase mb-1"
    />
    <Text size="sm" weight="normal" color="gray-500" :content="helpText" />
    <div class="relative max-w-sm w-full h-12">
      <Icon
        name="ChevronDownIcon"
        color="primary"
        size="6"
        isOutline
        customClass="absolute right-2 top-6 cursor-pointer"
      />
      <select
        v-model="selected"
        name="location relative"
        @focus="$emit('onFocus')"
        class="
          appearance-none
          outline-none
          mt-3
          h-12
          px-3
          rounded
          border
          focus:border-primary
          max-w-sm
          w-full
          absolute
          inset-0
        "
        style="background-color: transparent"
        @change="
          (event) => {
            $emit('update:selectedItem', event.target.value);
            $emit('onFocus');
          }
        "
      >
        <option value="" disabled>
          {{ placeholder }}
        </option>
        <option
          v-for="option in items"
          :key="option"
          :value="option"
          class="text-gray-900"
        >
          {{ option }}
        </option>
      </select>
    </div>
  </div>
</template>
<script>
import Text from "../../../atoms/Text/Text.vue";
import Icon from "../../../atoms/Icons/Icons.vue";

export default {
  components: {
    Text,
    Icon,
  },
  props: {
    /**
     * items to show in select
     */
    items: {
      type: Array,
      default: () => [],
    },
    /**
     * used to set predefined value.
     */
    selectedItem: {
      type: Object,
      default: () => {},
    },
    /**
     * label of select
     */
    label: {
      type: String,
      default: "",
    },
    /**
     * help text of select
     */
    helpText: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  computed: {
    selected: {
      get() {
        return this.selectedItem || "";
      },
      set(newValue) {
        this.$emit("update:selectedItem", newValue);
      },
    },
  },
};
</script>

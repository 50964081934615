<template>
    <div class="border shadow flex flex-col rounded w-full">
        <div class="w-full flex items-center pl-5 py-4 border-b">
            <Text color="gray-900" size="lg" :content="title" weight="bold" custom-class="mr-4" />
            <Text content="Edit" @click="$emit('onEditRow')" color="primary" custom-class="cursor-pointer"/>
        </div>
        <div :class="[index%2 === 0 ? 'bg-gray-100' : 'bg-white', index !== 0 ? 'border-t' : '']"
             class="w-full flex items-center" v-for="(row, index) in rows">
            <Text size="sm" color="gray-400" weight="medium" custom-class="uppercase border-r p-5 flex-1" :content="row.label" />
            <Text size="sm" :color="themeColor" weight="medium" :custom-class="`p-5 text-right w-${valueWidth}`" :content="getValue(row.value)" />
        </div>
    </div>
</template>

<script>
    import Text from "../../atoms/Text/Text.vue";
    import Link from "../../atoms/Link/Link.vue";
    export default {
        name: "TableBox",
        components: {
            Link,
            Text
        },
        props: {
            themeColor: {
                type: String,
                default: 'primary'
            },
            valueWidth: {
                type: String,
                default: '40'
            },
            title: {
                type: String,
                default: ""
            },
            rows: {
                type: Array,
                default: () => []
            },
        },
        methods: {
            getValue(value) {
                if(value === true) return 'Yes'
                else if(value === false) return 'No'
                return value
            }
        }
    };
</script>


<style>
</style>

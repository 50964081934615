<template>
  <div class="sm:px-4 lg:px-10">
    <div class="border-b pb-5">
      <Text size="2xl lg:text-4xl" color="gray-600" weight="semibold" :content="form.name" />
    </div>
    <div class="divide-y">
      <div v-for="field in form.questions" :key="field.id" class="flex flex-col" :class="(field.question === 'ARE EITHER OF YOUR PARENTS A DISLOCATED WORKER?' && !formObject['IN 2022 OR 2023, HAS ANYONE IN YOUR HOME RECEIVED BENEFITS FROM ANY DESIGNATED FEDERAL BENEFIT PROGRAMS?']) || field.question !== 'ARE EITHER OF YOUR PARENTS A DISLOCATED WORKER?' ? 'py-10' : 'py-0'">
        <p v-if="(field.question === 'ARE EITHER OF YOUR PARENTS A DISLOCATED WORKER?' && !formObject['IN 2022 OR 2023, HAS ANYONE IN YOUR HOME RECEIVED BENEFITS FROM ANY DESIGNATED FEDERAL BENEFIT PROGRAMS?']) || field.question !== 'ARE EITHER OF YOUR PARENTS A DISLOCATED WORKER?'" class="mb-2">
          <Text size="sm" weight="semibold" color="gray-700" :content="field.question" customClass="uppercase" element="span" />
          <span>
            <Icons
              @click="
                () => {
                  isOpen = true;
                  content = field.explanation;
                }
              "
              custom-class="cursor-pointer inline ml-2"
              v-if="field.isHelp && ((field.question === 'ARE EITHER OF YOUR PARENTS A DISLOCATED WORKER?' && !formObject['IN 2022 OR 2023, HAS ANYONE IN YOUR HOME RECEIVED BENEFITS FROM ANY DESIGNATED FEDERAL BENEFIT PROGRAMS?']) || field.question !== 'ARE EITHER OF YOUR PARENTS A DISLOCATED WORKER?')"
              name="ExclamationCircleIcon"
              size="5"
              color="yellow-500"
            />
          </span>
        </p>
        <Text v-if="field.helpText" size="sm" weight="normal" color="gray-500" v-html="field.helpText" />
        <Text v-if="field.question === 'WHAT WAS YOUR PARENTS ADJUSTED GROSS INCOME IN 2022?' && formObject['WHICH FORM DID YOUR PARENTS USE TO FILE THEIR TAXES IN 2022?'] && formObject['WHICH FORM DID YOUR PARENTS USE TO FILE THEIR TAXES IN 2022?'] !== 'Not Required To File'" size="sm" weight="normal" color="gray-500" v-html="`This is found on your parent's 2022 tax returns: <b>${grossIncomeInfo(formObject['WHICH FORM DID YOUR PARENTS USE TO FILE THEIR TAXES IN 2022?'])}</b>`" />
        <template v-if="field.inputType === FIELD_TYPES.TEXT">
          <TextInput :id="field.id" v-model:value="formObject[field.question]" type="text" :placeholder="field.question === 'What is your birthday?' ? 'MMDDYYYY' : 'Please Input a value'" :inputMask="field.question === 'What is your birthday?' ? '########' : ''" @onFocus="() => $emit('onFocus', field.helpBlurb)" />
        </template>
        <template v-if="field.inputType === FIELD_TYPES.PRICE">
          <AmountInput :id="field.id" v-model:value="formObject[field.question]" currency="$" placeholder="Please Input a value" @onFocus="() => $emit('onFocus', field.helpBlurb)" />
        </template>
        <template v-else-if="field.inputType === FIELD_TYPES.RADIO">
          <SimpleRadioGroup v-model:value="formObject[field.question]" :items="JSON.parse(field.options)" :inline="showInlineRadioGroup" is-simple-list @onFocus="() => $emit('onFocus', field.helpBlurb)" />
        </template>
        <template v-else-if="field.inputType === FIELD_TYPES.RADIO_GRID">
          <RadioGrid v-model:selected-item="formObject[field.question]" :items="JSON.parse(field.options)" @onFocus="() => $emit('onFocus', field.helpBlurb)" />
        </template>
        <template v-else-if="field.inputType === FIELD_TYPES.SELECT">
          <Select v-model:selected-item="formObject[field.question]" :items="JSON.parse(field.options)" placeholder="Please select a value" @onFocus="() => $emit('onFocus', field.helpBlurb)" />
        </template>
        <template v-else-if="field.inputType === FIELD_TYPES.TEXT_AREA">
          <TextArea v-model:value="formObject[field.question]" placeholder="Type here" custom-class="mr-1 border border-gray-300" @onFocus="() => $emit('onFocus', field.helpBlurb)" />
        </template>
        <template v-else-if="field.inputType === FIELD_TYPES.CHECKBOX">
          <CheckboxList v-model:selected="formObject[field.question]" :items="JSON.parse(field.options)" @onFocus="() => $emit('onFocus', field.helpBlurb)" />
        </template>
        <template v-else-if="field.inputType === FIELD_TYPES.TAGS">
          <MultiSelect v-model:selected="formObject[field.question]" :options="JSON.parse(field.options)" @onFocus="() => $emit('onFocus', field.helpBlurb)" />
        </template>
        <template v-else-if="field.inputType === FIELD_TYPES.BOOLEAN_SELECT">
          <NativeSelect v-model:selected-item="booleanObject[field.question]" :items="booleanSelectOptions" :selection-changed="handleSelectionChanged(field.question)" @onFocus="() => $emit('onFocus', field.helpBlurb)" />
        </template>
        <template v-else-if="field.inputType === FIELD_TYPES.BOOLEAN">
          <RadioGrid v-if="(field.question === 'ARE EITHER OF YOUR PARENTS A DISLOCATED WORKER?' && !formObject['IN 2022 OR 2023, HAS ANYONE IN YOUR HOME RECEIVED BENEFITS FROM ANY DESIGNATED FEDERAL BENEFIT PROGRAMS?']) || field.question !== 'ARE EITHER OF YOUR PARENTS A DISLOCATED WORKER?'" v-model:selected-item="booleanObject[field.question]" :items="[booleanTrueLabel, booleanFalseLabel]" :selectionChanged="selectionChanged(field.question)" @onFocus="() => $emit('onFocus', field.helpBlurb)" />
        </template>
        <template v-else-if="field.inputType === FIELD_TYPES.PRICESELECT">
          <div class="text-sm text-gray-400 font-semibold py-8 text-center">Component not available</div>
        </template>
        <div v-if="field.question === 'WHAT IS THE CURRENT BALANCE YOUR PARENTS HAVE IN EDUCATIONAL ACCOUNTS'" class="divide-y mt-10 question-wrapper">
          <div v-for="(item, itemIndex) in homeQuestions" :key="itemIndex" class="divide-y">
            <div v-for="(question, questionIndex) in item" :key="questionIndex" class="py-10 flex flex-col">
              <Text size="sm" weight="semibold" color="gray-700" :content="question.question" customClass="uppercase mb-2" />
              <template v-if="question.inputType === FIELD_TYPES.BOOLEAN">
                <RadioGrid v-model:selected-item="question.answer" :items="[booleanTrueLabel, booleanFalseLabel]" :selectionChanged="onSelectionChanged(itemIndex, 'home')" />
              </template>
              <template v-else-if="question.inputType === FIELD_TYPES.PRICE">
                <AmountInput v-model:value="question.answer" currency="$" placeholder="Please Input a value" />
              </template>
            </div>
          </div>
          <div v-for="(item, itemIndex) in farmQuestions" :key="itemIndex" class="divide-y">
            <div v-for="(question, questionIndex) in item" :key="questionIndex" class="py-10 flex flex-col">
              <Text size="sm" weight="semibold" color="gray-700" :content="question.question" customClass="uppercase mb-2" />
              <template v-if="question.inputType === FIELD_TYPES.BOOLEAN">
                <RadioGrid v-model:selected-item="question.answer" :items="[booleanTrueLabel, booleanFalseLabel]" :selectionChanged="onSelectionChanged(itemIndex, 'farm')" />
              </template>
              <template v-else-if="question.inputType === FIELD_TYPES.PRICE">
                <AmountInput v-model:value="question.answer" currency="$" placeholder="Please Input a value" />
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Explanation :open="isOpen" :text-html="content" :on-no-click="() => (isOpen = false)" />
  </div>
</template>

<script>
import { ref } from "vue";
import Text from "../../../atoms/Text/Text.vue";
import TextInput from "../../../molecules/Inputs/Component/Component.vue";
import SimpleRadioGroup from "../../../molecules/RadioGroup/Simple/Simple.vue";
import Select from "../../../molecules/Selects/WithCustomArrow/WithCustomArrow.vue";
import NativeSelect from "../../../molecules/Selects/SimpleNative/SimpleNative.vue";
import Button from "../../../atoms/Button/Button.vue";
import RadioGrid from "../../../molecules/RadioGroup/GridWithLabel/GridWithLabel.vue";
import TextArea from "../../../molecules/Textareas/Simple/Simple.vue";
import CheckboxList from "../../../molecules/Checkboxes/SimpleList/SimpleList.vue";
import MultiSelect from "../../../molecules/Dropdown/MultiSelectDropdown/MultiSelectDropdown.vue";
import AmountInput from "../../../molecules/Inputs/AmountInput/AmountInput.vue";
import Icons from "@/components/atoms/Icons/Icons";
import Explanation from "@/components/organisms/Modals/Explanation/Explanation";

/**
 - Use it to show a application form
 **/
export default {
  components: {
    Explanation,
    Icons,
    Button,
    TextInput,
    Text,
    SimpleRadioGroup,
    Select,
    RadioGrid,
    TextArea,
    MultiSelect,
    CheckboxList,
    NativeSelect,
    AmountInput,
  },
  props: {
    form: {
      type: Object,
      default: () => {},
    },
    /**
     * to toggle orientation of simple radio group view
     */
    showInlineRadioGroup: {
      type: Boolean,
      default: false,
    },
    /**
     * options array for boolean select dropdown
     */
    booleanSelectOptions: {
      type: Array,
      default: () => [
        { value: "true", text: "Yes" },
        { value: "false", text: "No" },
      ],
    },
    booleanTrueLabel: {
      type: String,
      default: "Yes",
    },
    booleanFalseLabel: {
      type: String,
      default: "No",
    },
    trueLabel: {
      type: String,
      default: "true",
    },
    farmQuestionList: {
      type: Array,
      default: ["DO YOUR PARENTS OWN A FARM, OR ANY PIECE OF LAND?", "DO YOUR PARENTS OWN ANOTHER FARM, OR ANY PIECE OF LAND?", "WHAT IS THE VALUE OF THEIR FARM, OR PIECE OF LAND?", "WHAT IS THE TOTAL MORTGAGE DEBT?"],
    },
    homeQuestionList: {
      type: Array,
      default: ["DO YOUR PARENTS OWN A HOME?", "DO YOUR PARENTS OWN ANOTHER HOME?", "WHAT IS THE VALUE OF THIS HOME?", "WHAT IS THE TOTAL MORTGAGE DEBT?"],
    },
  },
  data() {
    const FIELD_TYPES = ref({
      TEXT: "TEXT",
      PRICE: "PRICE",
      PRICESELECT: "PRICESELECT",
      RADIO: "RADIO",
      RADIO_GRID: "RADIO_GRID",
      CHECKBOX: "CHECKBOX",
      SELECT: "SELECT",
      BOOLEAN: "BOOLEAN",
      TEXT_AREA: "TEXTAREA",
      BOOLEAN_SELECT: "BOOLEAN_SELECT",
      TAGS: "TAGS",
    });
    const formObject = ref({});
    const booleanObject = ref({});
    const homeQuestions = ref([
      [
        {
          question: this.homeQuestionList[0],
          answer: "No",
          inputType: "BOOLEAN",
        },
      ],
    ]);
    const farmQuestions = ref([
      [
        {
          question: this.farmQuestionList[0],
          answer: "No",
          inputType: "BOOLEAN",
        },
      ],
    ]);
    return {
      FIELD_TYPES,
      formObject,
      booleanObject,
      farmQuestions,
      homeQuestions,
      isOpen: false,
      content: null,
    };
  },
  methods: {
    getFormObject() {
      return this.formObject;
    },
    setFormObject(object) {
      this.formObject = object;
      if (object.parentsOwnedFarmOrLand) {
        const setQuestionsData = (data, type) => {
          if (data.length > 0) {
            const questions = [...data].map((item, index) => [
              {
                question: type === "home" ? (index === 0 ? this.homeQuestionList[0] : this.homeQuestionList[1]) : index === 0 ? this.farmQuestionList[0] : this.farmQuestionList[1],
                answer: "Yes",
                inputType: "BOOLEAN",
              },
              {
                question: type === "home" ? this.homeQuestionList[2] : this.farmQuestionList[2],
                answer: item?.valueOfFarmOrLand,
                inputType: "PRICE",
              },
              {
                question: type === "home" ? this.homeQuestionList[3] : this.farmQuestionList[3],
                answer: item?.mortgagePayoff,
                inputType: "PRICE",
              },
            ]);
            this[type === "home" ? "homeQuestions" : "farmQuestions"] = [
              ...questions,
              [
                {
                  question: type === "home" ? this.homeQuestionList[1] : this.farmQuestionList[1],
                  answer: "No",
                  inputType: "BOOLEAN",
                },
              ],
            ];
          } else {
            this[type === "home" ? "homeQuestions" : "farmQuestions"] = [
              [
                {
                  question: type === "home" ? this.homeQuestionList[0] : this.farmQuestionList[0],
                  answer: "No",
                  inputType: "BOOLEAN",
                },
              ],
            ];
          }
        };
        //home
        const homeData = object.parentsOwnedFarmOrLand.filter((item) => item.isHome === true);
        setQuestionsData(homeData, "home");

        //farmOrLand
        const farmOrLandData = object.parentsOwnedFarmOrLand.filter((item) => !item.isHome);
        setQuestionsData(farmOrLandData, "farm");
      }
    },
    setBooleanObject(object) {
      this.booleanObject = object;
    },
    selectionChanged(name) {
      this.formObject[name] = this.booleanObject[name] === this.booleanTrueLabel;
    },
    handleSelectionChanged(name) {
      this.formObject[name] = this.booleanObject[name] === this.trueLabel;
    },
    getFarmQuesttions() {
      return this.farmQuestions;
    },
    getHomeQuestions() {
      return this.homeQuestions;
    },
    onSelectionChanged(index, type) {
      const questions = type == "home" ? [...this.homeQuestions] : [...this.farmQuestions];
      const answer = questions?.[index]?.[0]?.answer;
      if (answer === "Yes" && index === questions.length - 1) {
        questions[index] = [
          ...questions[index],
          {
            question: type == "home" ? this.homeQuestionList[2] : this.farmQuestionList[2],
            answer: "",
            inputType: "PRICE",
          },
          {
            question: type == "home" ? this.homeQuestionList[3] : this.farmQuestionList[3],
            answer: "",
            inputType: "PRICE",
          },
        ];
        questions.push([
          {
            question: type == "home" ? this.homeQuestionList[1] : this.farmQuestionList[1],
            answer: "No",
            inputType: "BOOLEAN",
          },
        ]);
        type == "home" ? (this.homeQuestions = [...questions]) : (this.farmQuestions = [...questions]);
      } else if (answer === "No" && index !== questions.length - 1) {
        questions.splice(index);
        questions[index] = [
          {
            question: index === 0 ? (type == "home" ? this.homeQuestionList[0] : this.farmQuestionList[0]) : type == "home" ? this.homeQuestionList[1] : this.farmQuestionList[1],
            answer: "No",
            inputType: "BOOLEAN",
          },
        ];
        type == "home" ? (this.homeQuestions = [...questions]) : (this.farmQuestions = [...questions]);
      }
    },
    grossIncomeInfo(formType) {
      const data = [
        { value: "1040", text: "On form 1040, this is line 11" },
        { value: "1040A", text: "On form 1040A, this is line 21" },
        { value: "1040EZ", text: "On form 1040EZ, this is line 4" },
      ];
      return data.find((item) => item.value === formType)?.text || "";
    },
  },
};
</script>
<style scoped>
.question-wrapper {
  border-top-width: 1px;
  border-top-color: rgba(113, 113, 122, 0.2);
}
</style>

<template>
  <div>
    <Text
      v-if="label"
      :content="label"
      size="sm"
      weight="medium"
      color="gray-700"
      :customClass="customLabelClass"
    />
    <div class="relative max-w-sm w-full h-12">
      <Icon
        name="ChevronDownIcon"
        color="primary"
        size="6"
        isOutline
        customClass="absolute right-2 top-6 cursor-pointer"
      />
      <select
        id="nativeSelect"
        v-model="selected"
        @focus="$emit('onFocus')"
        name="location"
        ref="mySelect"
        class="
          max-w-sm
          mt-3
          appearance-none
          outline-none
          w-full
          px-3
          h-12
          text-base
          border border-gray-300
          focus:outline-none
          focus:ring-primary
          focus:border-primary
          sm:text-sm
          rounded-md
          absolute
          inset-0
        "
        :class="[
          `${selected === '' ? 'text-gray-400' : ''}`,
          customSelectClass,
        ]"
        style="background-color: transparent"
        @change="selectedItemChanged"
      >
        <option value="" disabled>
          {{ placeholder }}
        </option>
        <option
          v-for="option in items"
          :key="isSimpleList ? option : option.value"
          :value="isSimpleList ? option : option.value"
          class="text-gray-900"
        >
          {{ isSimpleList ? option : option.text }}
        </option>
      </select>
    </div>
  </div>
</template>
<script>
/**
 - Native select used to display a list of items
 */
import Text from "../../../atoms/Text/Text.vue";
import Icon from "../../../atoms/Icons/Icons.vue";
import { ref } from "vue";
export default {
  name: "NativeSelect",
  components: {
    Text,
    Icon,
  },
  props: {
    /**
     * items to show in select
     */
    items: {
      type: Array,
      default: () => [],
    },
    /**
     * used to set predefined value.
     */
    selectedItem: {
      type: Object,
      default: () => {},
    },
    /**
     * label of select
     */
    label: {
      type: String,
      default: "",
    },
    /**
     * placeholder of select
     */
    placeholder: {
      type: String,
      default: "Select an option",
    },
    /**
     * extra class of label
     */
    customLabelClass: {
      type: String,
      default: "",
    },
    /**
     * extra class of select
     */
    customSelectClass: {
      type: String,
      default: "",
    },
  },
  computed: {
    selected: {
      get() {
        return this.selectedItem || "";
      },
      set(newValue) {
        this.$emit("update:selectedItem", newValue);
      },
    },
  },
  methods: {
    selectedItemChanged(evt) {
      this.$emit("update:selectedItem", evt.target.value);
      if (this.selectionChanged) {
        this.selectionChanged(evt.target.value);
      }
    },
  },
};
</script>


<template>
  <div>
    <div class="sm:border-b w-full">
      <div class="max-w-7xl mx-auto">
        <div class="block sm:flex items-center sm:space-x-6 w-full scrollbar-hide mx-0">
          <a v-for="(step, stepIdx) in steps" :key="stepIdx" class="no-underline sm:py-4 relative cursor-pointer"   @click="stepChanged(stepIdx)">
            <div v-if="step.status === 'current'" class="hidden sm:block h-0.5 absolute w-full left-0 bottom-0 bg-primary"></div>
            <Text size="xs" :color="step.status === 'current' ? 'primary': 'gray-400'" customClass="whitespace-nowrap py-3 sm:py-0" :content="step.name"/>
          </a>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import Text from '../../../atoms/Text/Text.vue'
/**
 * - Use it when show steps with underline in shadow container
 */
export default {
  components: {
    Text
  },
  props: {
    /**
     * steps array
     */
    items: {
      type: Array,
      default: () => [],
    },
    /**
     * action called on changing a step with updated steps array and current step info
     */
    stepClicked: {
      type: Function,
      default: () => {},
    },
    /**
     * when set to true allows to traverse steps only in sequence
     */
    dependancy: {
      type: Boolean,
      default: false,
    },
		stepIndex: {
    	type: Number,
			default: 0
		}
  },
  watch: {
    items: function(val) {
      this.steps = val
    },
		stepIndex: function (val) {
			this.steps[val].status = "current";
		}
  },
  setup(props) {
    const steps = ref(props.items);
    const nextClicked = () => {
      let currentStepIndex = getCurrentStepIndex();
      stepChanged(currentStepIndex + 1);
    };
    const previousClicked = () => {
      let currentStepIndex = getCurrentStepIndex();
      stepChanged(currentStepIndex - 1);
    };
    const setCurrentStepStatus = (index) => {
      steps.value[index].status = "current";
      props.stepClicked(steps.value, index);
    };
    const getCurrentStepIndex = () => {
      return steps.value.findIndex((e) => e.status === "current");
    };
    const stepChanged = (index) => {
      const currentStepIndex = getCurrentStepIndex();
      if (currentStepIndex >= 0) {
        if (
          !props.dependancy ||
          (props.dependancy &&
            (currentStepIndex + 1 === index ||
              steps.value[index].status === "complete" ||
              (index > 0 && steps.value[index - 1].status === "complete")))
        ) {
          steps.value[currentStepIndex].status = "complete";
          setCurrentStepStatus(index);
        }
      } else {
        let currentIndex = props.dependancy ? 0 : index;
        setCurrentStepStatus(currentIndex);
      }
    };

    return {
      steps,
      stepChanged,
      nextClicked,
      previousClicked,
    };
  },
};
</script>

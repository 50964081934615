
<template>
  <div>
    <Text size="sm" weight="semibold" color="gray-700" :content="label" customClass="uppercase mb-1" element="span"/>
    <Text size="sm" weight="normal" color="gray-500" :content="helpText"/>
    <div class="grid grid-cols-1 sm:grid-cols-3 gap-x-8 gap-y-3 mt-3">
      <a v-for="item in items" :key="item" class="p-2 rounded h-12 flex justify-center items-center no-underline" @click="() => {selected = item; $emit('onFocus')}" :class="selected === item ? 'border-2 border-primary' : 'border'">
        <Text size="sm" color="gray-500" :content="item"/>
      </a>
    </div>
  </div>
</template>

<script>
import Icon from "../../../atoms/Icons/Icons.vue";
import Text from '../../../atoms/Text/Text.vue'

export default {
  components: {
    Icon,
    Text,
  },
  props: {
    /**
     * items to show as radiogroup options
     */
    items: {
      type: Array,
      default: () => [],
    },
    /**
     * for assigning predefined value
     */
    selectedItem: {
      type: String,
      default: () => {},
    },
    /**
     * to display a heading
     */
    label: {
      type: String,
      default: "",
    },
    helpText: {
      type: String,
      default: ''
    },
    selectionChanged: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    selected: {
      get() {
        return this.selectedItem;
      },
      set(newValue) {
        this.$emit("update:selectedItem", newValue);
        if (this.selectionChanged) {
          this.selectionChanged(newValue);
        }
      },
    },
  },
};
</script>

<template>
    <div>
        <Text color="gray-900" size="4xl" :content="title" weight="bold" custom-class="mr-4" />
        <div v-for="(table, i) in tableBoxProps" class="mt-10">
            <TableBox v-bind="table" @onEditRow="() => $emit('onEditRow', i)"/>
        </div>
        <div class="w-full flex justify-end sm:justify-center mt-10">
            <Button :content="btnLabel" size="xl" :custom-class="`bg-${themeColor}`" @click="onFinish" />
        </div>
    </div>
</template>

<script>
    import TableBox from "../../molecules/TableBox/TableBox.vue";
    import Text from "../../atoms/Text/Text.vue";
    import Button from "../../atoms/Button/Button.vue";
    export default {
        name: "Summary",
        components: {
            Button,
            Text,
            TableBox
        },
        props: {
            themeColor: {
                type: String,
                default: 'primary'
            },
            title: {
                type: String,
                default: ""
            },
            tableBoxProps: {
                type: Array,
                default: () => []
            },
            btnLabel: {
                type: String,
                default: ""
            },
            onFinish: {
                type: Function,
                default: () => {},
            }
        },
    };
</script>


<style>
</style>
